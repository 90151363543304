import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carousels = document.querySelectorAll('.programs-carousel__carousel');

  function initCarousel(carousel, wrapper, callback) {
    const slides = carousel.querySelectorAll('.swiper-slide');

    if (4 > slides.length) {
      wrapper.classList.add('centered-desktop');
    }

    new Swiper(carousel, {
      slidesPerView: 1.2,
      spaceBetween: 16,
      centerInsufficientSlides: true,
      navigation: {
        prevEl: carousel.querySelector('.swiper-button-prev'),
        nextEl: carousel.querySelector('.swiper-button-next'),
      },
      breakpoints: {
        600: {
          slidesPerView: 2.4,
          spaceBetween: 16,
        },
        1200: {
          slidesPerView: 'auto',
          spaceBetween: 24,
        },
      },
    });

    callback(wrapper);
  }

  function showCarousel(wrapper) {
    wrapper.classList.add('loaded');
  }

  if (0 < carousels.length) {
    carousels.forEach((carousel) => {
      const wrapper = carousel.parentElement;
      initCarousel(carousel, wrapper, showCarousel);
    });
  }
});
